import React from "react";
import styled from "styled-components";
import BaseButton from "../buttons/BaseButton";
import { HIGHLIGHT_PRIMARY, HIGHLIGHT_PRIMARY_LIGHT } from "../../colors";
import { PARAGRAPH_LARGE_BOLD } from "../../typography";

const Wrapper = styled.div`
  display: flex;
`;

const NumberButton = styled(BaseButton)`
  ${PARAGRAPH_LARGE_BOLD}
  width: 40px;
  height: 40px;
  border-radius: 10px;
  margin-right: 3px;

  ${(props) =>
    props.selected &&
    `
    opacity: 1;
    background-color: ${HIGHLIGHT_PRIMARY};

    &:hover:enabled {
      background-color: ${HIGHLIGHT_PRIMARY_LIGHT};
    }
  `}
`;

const NPSInput = ({ value, onChange }) => {
  const handleClick = (newValue) => () => {
    onChange(newValue);
  };

  return (
    <Wrapper data-cy={"NPSInput"}>
      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((option) => (
        <NumberButton
          key={option}
          selected={value === option}
          noSpinner
          onClick={handleClick(option)}
          data-cy-value={option}
        >
          {option}
        </NumberButton>
      ))}
    </Wrapper>
  );
};

export default NPSInput;
