import React from "react";
import calculateNpsScore from "../../../util/calculateNpsScore";
import Table from "../tables/Table";
import THead from "../tables/THead";
import TR from "../tables/TR";
import TH from "../tables/TH";
import TBody from "../tables/TBody";
import TD from "../tables/TD";
import TFoot from "../tables/TFoot";

const GameSurveyResults = ({ responses }) => {
  const avQualityAvg = React.useMemo(() => {
    const responsesWithAVQuality = responses.filter((response) =>
      Number.isInteger(response.avQuality)
    );

    const sum = responsesWithAVQuality.reduce(
      (sum, response) => response.avQuality + sum,
      0
    );
    return sum / responsesWithAVQuality.length;
  }, [responses]);

  const npsScore = React.useMemo(() => {
    return calculateNpsScore(
      responses
        .map((response) => response.npsScore)
        .filter((score) => Number.isInteger(score))
    );
  }, [responses]);

  return (
    <Table>
      <THead>
        <TR>
          <TH>Submitted By</TH>
          <TH>AV Quality</TH>
          <TH>NPS Score</TH>
          <TH>Improvement Advice</TH>
        </TR>
      </THead>
      <TBody>
        {responses.map((response) => (
          <TR key={response.id}>
            <TD>{response.submittedBy}</TD>
            <TD>{response.avQuality}</TD>
            <TD>{response.npsScore}</TD>
            <TD>{response.improvementAdvice}</TD>
          </TR>
        ))}
      </TBody>
      <TFoot>
        <TR>
          <TD> </TD>
          <TD>{avQualityAvg ? avQualityAvg.toPrecision(2) : " "}</TD>
          <TD>{npsScore || " "}</TD>
          <TD> </TD>
        </TR>
      </TFoot>
    </Table>
  );
};

export default GameSurveyResults;
