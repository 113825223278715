import React from "react";
import styled from "styled-components";
import JoiningLayout from "../../components/layouts/JoiningLayout";
import { PARAGRAPH_NORMAL } from "../../typography";

const Text = styled.div`
  ${PARAGRAPH_NORMAL}
`;

const SurveyThankYouScreen = () => {
  return (
    <JoiningLayout title={"Thank You"}>
      <Text>
        We are doing our best to improve and your feedback is vital to that, so
        we really appreciate your response!
      </Text>
    </JoiningLayout>
  );
};

export default SurveyThankYouScreen;
