import React, { useContext } from "react";
import PageWrapper from "../components/PageWrapper";
import Survey from "../components/Survey";
import { SessionDataContextProvider } from "../contexts/SessionDataContext";
import FeatureFlagContext from "../contexts/FeatureFlagContext";
import inTesting from "../util/inTesting";
import SurveyController from "../v2/controllers/SurveyController";

export default function Home() {
  const { flags, flagsReady } = useContext(FeatureFlagContext);

  if (!flagsReady && !inTesting()) {
    return null;
  }

  const v2 = flags["v2"];

  if (v2) {
    return (
      <SessionDataContextProvider>
        <SurveyController />
      </SessionDataContextProvider>
    );
  }

  return (
    <PageWrapper>
      <SessionDataContextProvider>
        <Survey />
      </SessionDataContextProvider>
    </PageWrapper>
  );
}
