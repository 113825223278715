import React from "react";
import styled from "styled-components";
import BaseButton from "../buttons/BaseButton";
import { HIGHLIGHT_PRIMARY, HIGHLIGHT_PRIMARY_LIGHT } from "../../colors";

const OPTIONS = ["😞", "🙁", "😐", "🙂", "😊"];

const Wrapper = styled.div`
  display: flex;
`;

const EmojiButton = styled(BaseButton)`
  font-size: 22px;
  width: 40px;
  height: 40px;
  border-radius: 10px;
  margin-right: 3px;

  ${(props) =>
    props.selected &&
    `
    opacity: 1;
    background-color: ${HIGHLIGHT_PRIMARY};

    &:hover:enabled {
      background-color: ${HIGHLIGHT_PRIMARY_LIGHT};
    }
  `}
`;

const SmileyInput = ({ value, onChange }) => {
  const handleClick = (newValue) => () => {
    onChange(newValue);
  };

  return (
    <Wrapper data-cy={"SmileyInput"}>
      {OPTIONS.map((option, index) => (
        <EmojiButton
          key={option}
          selected={value === index + 1}
          noSpinner
          onClick={handleClick(index + 1)}
          data-cy-value={index + 1}
        >
          {option}
        </EmojiButton>
      ))}
    </Wrapper>
  );
};

export default SmileyInput;
