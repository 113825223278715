import React from "react";
import styled from "styled-components";
import JoiningLayout from "../../components/layouts/JoiningLayout";
import { PARAGRAPH_NORMAL } from "../../typography";
import { WHITE } from "../../colors";
import LargeMainButton from "../../components/buttons/LargeMainButton";
import SmileyInput from "../../components/inputs/SmileyInput";
import NPSInput from "../../components/inputs/NPSInput";
import MainTextarea from "../../components/inputs/MainTextarea";

const Question = styled.div`
  margin: 30px 0;
`;

const QuestionText = styled.div`
  ${PARAGRAPH_NORMAL}
  color: ${WHITE};
  margin-bottom: 15px;
`;

const ButtonWrapper = styled.div`
  margin-top: 25px;
  display: flex;
  justify-content: center;
`;

const SurveyQuestionsScreen = ({
  AVQualityValue,
  onAVQualityChange,
  NPSValue,
  onNPSChange,
  improveValue,
  onImproveChange,
  onSubmit,
}) => {
  return (
    <JoiningLayout wide title={"End of Quiz Survey"}>
      <Question>
        <QuestionText>
          How happy were you with the audio and video quality?
        </QuestionText>
        <SmileyInput value={AVQualityValue} onChange={onAVQualityChange} />
      </Question>
      <Question>
        <QuestionText>
          How likely are you to recommend us to someone you know?
        </QuestionText>
        <NPSInput value={NPSValue} onChange={onNPSChange} />
      </Question>
      <Question>
        <QuestionText>
          Can you think of anything we can do to improve?
        </QuestionText>
        <MainTextarea
          value={improveValue}
          onChange={(event) => onImproveChange(event.target.value)}
          data-cy={"ImprovementAdvice"}
        />
      </Question>
      <ButtonWrapper>
        <LargeMainButton onClick={onSubmit}>Submit</LargeMainButton>
      </ButtonWrapper>
    </JoiningLayout>
  );
};

export default SurveyQuestionsScreen;
