import React, { useState } from "react";
import useRespondToSurveyMutation from "../../../hooks/mutations/useRespondToSurveyMutation";
import SurveyQuestionsScreen from "../../screens/survey/SurveyQuestionsScreen";
import SurveyThankYouScreen from "../../screens/survey/SurveyThankYouScreen";

const SurveyQuestionsController = () => {
  const [respondToSurveyMutation] = useRespondToSurveyMutation();
  const [submitted, setSubmitted] = useState(false);
  const [avQuality, setAvQuality] = useState();
  const [npsScore, setNpsScore] = useState();
  const [improvementAdvice, setImprovementAdvice] = useState();

  const handleSubmit = async () => {
    await respondToSurveyMutation({
      variables: {
        avQuality,
        npsScore,
        improvementAdvice,
      },
    });
    setSubmitted(true);
  };

  return (
    <>
      {!submitted && (
        <SurveyQuestionsScreen
          AVQualityValue={avQuality}
          onAVQualityChange={setAvQuality}
          NPSValue={npsScore}
          onNPSChange={setNpsScore}
          improveValue={improvementAdvice}
          onImproveChange={setImprovementAdvice}
          onSubmit={handleSubmit}
        />
      )}
      {submitted && <SurveyThankYouScreen />}
    </>
  );
};

export default SurveyQuestionsController;
