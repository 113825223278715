import styled from "styled-components";
import BaseTextarea from "./BaseTextarea";
import {
  HIGHLIGHT_PRIMARY,
  PLATFORM_ERROR,
  TEXT_DEFAULT,
  TEXT_LIGHT,
  WHITE,
} from "../../colors";
import { PARAGRAPH_NORMAL_BOLD } from "../../typography";

const MainTextarea = styled(BaseTextarea)`
  background-color: ${WHITE};
  color: ${TEXT_DEFAULT};
  ${PARAGRAPH_NORMAL_BOLD};
  border: 2px solid ${WHITE};

  &:focus,
  &.focus {
    border-color: ${HIGHLIGHT_PRIMARY};
    outline: none;

    ${({ error }) =>
      error &&
      `
    border-color: ${PLATFORM_ERROR};
  `}
  }

  &::placeholder {
    ${PARAGRAPH_NORMAL_BOLD}
    color: ${TEXT_LIGHT};
  }

`;

export default MainTextarea;
