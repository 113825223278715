/* eslint-disable no-console */

import { useEffect } from "react";

const useMonitorValue = (value, handler = null) => {
  useEffect(() => {
    if (typeof handler === "function") {
      handler(value);
    } else if (typeof handler === "string") {
      console.log(handler, value);
    } else {
      console.log(value);
    }
  }, [value, handler]);
};

export default useMonitorValue;
