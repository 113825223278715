import React from "react";
import styled from "styled-components";
import BaseButton from "./BaseButton";
import { ELEMENT_DARK, ELEMENT_DEFAULT } from "../../colors";
import { BUTTON_LARGE } from "../../typography";

const StyledButton = styled(BaseButton)`
  background-color: ${ELEMENT_DEFAULT};
  ${BUTTON_LARGE}
  padding: 10px 25px;

  &:hover:enabled,
  &.hover {
    background-color: ${ELEMENT_DARK};
  }
`;

const LargeDarkButton = ({ spinnerSize = 14, ...rest }) => (
  <StyledButton spinnerSize={spinnerSize} {...rest} />
);

export default LargeDarkButton;
