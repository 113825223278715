import styled from "styled-components";
import { PARAGRAPH_NORMAL } from "../../typography";
import TFoot from "./TFoot";

const TD = styled.td`
  ${PARAGRAPH_NORMAL};
  padding: 5px 0;

  ${TFoot} & {
    border-top: 1px solid currentColor;
  }
`;

export default TD;
