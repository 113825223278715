import React, { useContext } from "react";
import { SurveyContextProvider } from "../../../contexts/SurveyContext";
import SurveyResultsController from "./SurveyResultsController";
import SurveyQuestionsController from "./SurveyQuestionsController";
import SessionDataContext from "../../../contexts/SessionDataContext";

const SurveyController = () => {
  const { loaded, myHostId, myEventHostId, myEventGroupHostId } = useContext(
    SessionDataContext
  );

  if (!loaded) {
    return null;
  }

  const showResults = myHostId || myEventHostId || myEventGroupHostId;

  return (
    <SurveyContextProvider>
      {!showResults && <SurveyQuestionsController />}
      {showResults && <SurveyResultsController />}
    </SurveyContextProvider>
  );
};

export default SurveyController;
