import React from "react";
import JoiningLayout from "../../components/layouts/JoiningLayout";
import GameSurveyResults from "../../components/surveys/GameSurveyResults";
import styled from "styled-components";

const ResultsWrapper = styled.div`
  margin-top: 20px;
`;

const Text = styled.div`
  margin: 20px 0;
`;

const SurveyResultsScreen = ({ name, publicId, numPlayers, responses }) => {
  return (
    <JoiningLayout wide title={`Survey Results: ${name} (${publicId})`}>
      {responses && responses.length > 0 && (
        <>
          {responses.length} out of {numPlayers} players responded
        </>
      )}
      {(!responses || responses.length === 0) && (
        <Text>Waiting for first response from {numPlayers} players</Text>
      )}
      {responses && responses.length > 0 && (
        <ResultsWrapper>
          <GameSurveyResults responses={responses} />
        </ResultsWrapper>
      )}
    </JoiningLayout>
  );
};

export default SurveyResultsScreen;
