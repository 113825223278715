import styled from "styled-components";

const BaseTextarea = styled.textarea`
  padding: 13px 20px;
  border-radius: 20px;
  width: 100%;
  box-sizing: border-box;

  ${({ disabled }) =>
    disabled &&
    `
    opacity: 0.5;
  `}
`;

export default BaseTextarea;
