import React, { useContext } from "react";
import SurveyResultsScreen from "../../screens/survey/SurveyResultsScreen";
import SurveyContext from "../../../contexts/SurveyContext";
import SessionDataContext from "../../../contexts/SessionDataContext";
import useGetCurrentEventForSurveyQuery from "../../../components/Survey/useGetCurrentEventForSurveyQuery";
import useGetCurrentQuizForSurveyQuery from "../../../components/Survey/useGetCurrentQuizForSurveyQuery";
import useMonitorValue from "../../hooks/useMonitorValue";

const SurveyResultsController = () => {
  const { responses } = useContext(SurveyContext);
  const { myPublicEventId } = useContext(SessionDataContext);
  const { data: eventData } = useGetCurrentEventForSurveyQuery();
  const { data: quizData } = useGetCurrentQuizForSurveyQuery();

  const event = eventData && eventData.event;
  const eventName = event && event.name;
  const eventPlayers =
    eventData && eventData.event && eventData.event.eventPlayers;
  const players = quizData && quizData.quiz && quizData.quiz.players;
  const eventPlayerCount = eventPlayers ? eventPlayers.length : 0;
  const playerCount = players ? players.length : 0;

  const quiz = quizData && quizData.quiz;
  const quizName = quiz && quiz.name;
  const publicQuizId = quiz && quiz.publicId;

  useMonitorValue(quizData);

  const name = myPublicEventId ? eventName : quizName;
  const publicId = myPublicEventId ? myPublicEventId : publicQuizId;
  const totalCount = myPublicEventId ? eventPlayerCount : playerCount;

  return (
    <SurveyResultsScreen
      name={name}
      publicId={publicId}
      numPlayers={totalCount}
      responses={responses}
    />
  );
};

export default SurveyResultsController;
